@import "../../assets//css/utilities/variables.css";

.userDashboardWrapper {
  background-color: #eee;
  padding-block: 20px;
}
.userDashboard {
  background-color: #fafff6;
  padding: 65px 30px 30px 30px;
  border-radius: 12px;
  min-height: calc(100vh - 40px);
  background: url(../../assets/images//BG3.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.leftSide h1 {
  background: var(--text-title-green);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 42.33px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.shapeOne {
  position: absolute;
  top: -180px;
  right: 0;
}

.shapeTwo {
  position: absolute;
  top: 40px;
  height: 110px;
  left: 70px;
}

/* delete modal */
.deletePopupWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  z-index: 2;
  /* opacity: 0.30000001192092896; */
  backdrop-filter: blur(3px);
  background: rgb(2 2 2 / 29%);
}
.deletePopupInner {
  max-width: 495px;
  width: 100%;
  height: 350px;
  border-radius: 18px;
  background: #fff;
  box-shadow: -2px 8px 25px 0px rgba(0, 0, 0, 0.25);
  padding: 40px;
}
.deletePopupInner img {
  width: 40px;
  margin-inline: auto;
  display: block;
  padding-bottom: 40px;
}
.deletePopupInner p {
  color: #1d1e2c;
  font-size: 16.33px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.163px;
}
.deletePopupInner .btnWrapper {
  display: flex;
  column-gap: 20px;
}
.deletePopupInner button {
  flex: 0 0 50%;
  width: 50%;
  margin-top: 20px;
}
.deletePopupInner .deleteConfirmButton,
.deletePopupInner .deleteCancelButton {
  border-radius: 12px;
  border: 1px solid #64b431;
  background: #64b431;
  color: #fff;
  font-family: Poppins;
  font-size: 16.33px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 1rem;
}
.deletePopupInner .deleteCancelButton {
  border-radius: 12px;
  border: 1px solid #64b431;
  background: #fff;
  box-shadow: -2px 8px 25px 0px rgba(0, 0, 0, 0.25);
  color: #64b431;
}
.singleImageTop {
  position: fixed;
  right: 100px;
  z-index: 1;
  bottom: 150px;
  right: 150px;
  width: 150px;
  height: 100px;
  object-fit: cover;
}

.singleImage {
  position: fixed;
  right: 100px;
  z-index: 1;
  bottom: 90px;
  right: 240px;
  width: 150px;
  height: 100px;
  object-fit: cover;
}
.disabledBackground {
  pointer-events: none;
  opacity: 0.7;
  filter: blur(2px);
}

@media only screen and (max-width: 576px) {
  .deletePopupInner {
    width: 97%;
    padding: 20px;
  }

  .deletePopupInner .btnWrapper {
    column-gap: 5px;
  }
  .leftSide h1 {
    font-size: 30.33px;
  }
}
