.haloEffect {
    border-radius: 50%;
    border: 5px solid rgba(255, 255, 255, 0.8); /* Halo border */
    width: 200px;
    height: 200px;
    object-fit: cover;
    transform: scaleX(-1);
    margin-bottom: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    color: black;
    padding: 20px;
    max-width: 500px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  .popup p {
    font-size: 1rem;
  }
  .cameraContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .removeBtn{
    position: absolute;
    border: none;
    right: 16px;
    top: 16px;
    font-size: 20px;
    background: transparent;
  }
  .imagePreviewBox{ 
    align-items: center;
    background-color: rgb(255, 255, 255);
    border-radius: 2px;
    position: relative;
    border: 1px dashed rgb(204, 204, 204);
    display: flex;
    flex-direction: column;
    line-height: 15px;
    margin-top: 10px;
    padding: 1rem;
    width: fit-content;
    justify-content: center;
    margin-inline: auto;
    border-radius: 20px;
    position: relative;
  }
  .cameraIcon{
    border: none;
    background: #08070829;
    margin-top: 20px;
    border-radius: 20px;
  }
  