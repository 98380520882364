:root {
  --text-green: #64b431;
  --sidebar-bg-color: #eee;
  --primary-bg-color: #64b431;
  --text-title-green: linear-gradient(180deg, #1d1e2c 0%, #64b431 100%);
  --primary-btn-bg: linear-gradient(180deg, #64b431 0%, #1d1e2c 75.34%);
  --border-green: #64b431;
  --text-black: #333;
}
body.orientation-mode {
  --text-green: #513e6d;
  --sidebar-bg-color: #513e6d;
  --primary-bg-color: #513e6d;
  --text-title-green: linear-gradient(180deg, #1d1e2c 0%, #513e6d 100%);
  --primary-btn-bg: linear-gradient(180deg, #513e6d 0%, #1d1e2c 75.34%);
  --border-green: #513e6d;
}
