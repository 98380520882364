@import "../../../assets/css/utilities/variables.css";

.inputLabel {
  font-size: 20px;
  color: #1d1e2c;
  line-height: 1.5;
  padding-left: 10px;
  margin-bottom: 5px;
  font-weight: 500;
}
.inputStyling {
  border-radius: 12px;
  background-color: #ffffff;
  border: 2px solid #1d1e2c;
  min-height: 48px;
  color: #565656;
  padding: 8px 12px;
  height: 48px;
  box-shadow: none;
}
.inputStyling:focus {
  border-color: var(--border-green);
  outline: none;
  box-shadow: none;
  color: var(--text-green);
}
/* .inputStyling::placeholder {
  color: #1D1E2C4D;
} */
.eyeIcon {
  position: absolute;
  font-size: 20px;
  top: 43px;
  right: 9px;
  cursor: pointer;
  color: #1d1e2c4d;
}
@media only screen and (max-width: 1500px) {
  .inputStyling {
    height: 47px;
  }
  .inputLabel {
    font-size: 18px;
    line-height: 30px;
  }
}
@media only screen and (min-width: 1500px) {
  .eyeIcon {
    top: 42px;
  }
}
