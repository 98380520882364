/* Reset Start  */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");

@import "utilities/variables";

body {
  font-family: "Poppins", sans-serif !important;
  color: #3c3c3c;
  background: #eee;
  letter-spacing: normal;
  position: relative;
  overflow-x: hidden;
}
.text-black {
  color: #333 !important;
}
.text-green {
  color: var(--border-green) !important;
}
.swal2-title {
  font-family: "Poppins", sans-serif !important;
  font-size: 18px !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: unset;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px transparent;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
* {
  box-sizing: border-box;
}

.active,
.active:focus {
  color: #02aeef !important;
}

body {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
}

ul li {
  margin: 0;
  font-size: 15px;
}

blockquote,
q {
  quotes: none;
}

a:hover {
  color: #fff;
}
.btn:focus {
  box-shadow: none;
}

::-moz-selection {
  color: #fff;
  background: #02aeef;
}

::selection {
  color: #fff;
  background: #02aeef;
}

img {
  max-width: 100%;
  height: auto;
  /* display: block; */
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-none {
  cursor: default;
  pointer-events: none;
}
button,
button:hover,
button:focus,
button:active,
.slick-slide,
.navbar,
.navbar-toggler:focus,
.navbar-toggler:hover {
  outline: none;
}
.fs-7 {
  font-size: 14px;
}

a {
  color: #fff;
  text-decoration: none;
  -webkit-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
  outline: none !important;
}

h1 {
  font-size: 42px;
  font-weight: 600;
  line-height: 60px;
  font-family: "poppins", sans-serif !important;
  margin: 0 0 25px;
  color: var(--text-green);
  /* color: linear-gradient(180deg, #1D1E2C 0%, #64B431 100%); */

  letter-spacing: 1.2px;
}

h3 {
  font-size: 48px;
  font-weight: 600;
  line-height: 58px;
  /* font-family: "Montserrat", sans-serif !important; */
  margin: 0 0 25px;
  color: #fff;
  letter-spacing: 1.2px;
}

h2 {
  font-size: 52px;
  font-weight: 700;
  line-height: 62px;
  /* font-family: "Montserrat", sans-serif !important; */
  margin: 0 0 15px;
  color: #fff;
  letter-spacing: 1.2px;
}

h5 {
  font-size: 26px;
  font-weight: 600;
  line-height: 33px;
  /* font-family: "Montserrat", sans-serif !important; */
  margin: 0;
  color: rgba(29, 30, 44, 1);
  letter-spacing: 1px;
  /* // text-align: center; */
}

h4 {
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  /* font-family: "Montserrat", sans-serif !important; */
  margin: 0 0 15px;
  color: #fff;
}

h6 {
  font-size: 24px;
  font-weight: 600;
  line-height: 46px;
  /* font-family: "Montserrat", sans-serif !important; */
  margin: 0 0 15px;
  color: #fff;
  letter-spacing: 1.2px;
}

p {
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
  color: #fff;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background: var(--primary-bg-color) !important;
}

.custom-text-danger {
  color: rgb(255, 61, 0) !important;
}
.custom-text-success {
  color: var(--text-green) !important;
}

/* table dropdown menu overflow*/
@media (max-width: 767px) {
  .table-responsive .dropdown-menu {
    position: static !important;
  }
}
@media (min-width: 768px) {
  .table-responsive {
    overflow: visible;
  }
}
/*  media querry  */

@media only screen and (min-width: 1900px) {
  .container {
    max-width: 1440px !important;
  }
}

@media only screen and (max-width: 991px) {
  h1 {
    font-size: 50px;
    line-height: 60px;
  }
  h2 {
    font-size: 40px;
    line-height: 50px;
  }
  h3 {
    font-size: 35px;
    line-height: 45px;
  }
  h4 {
    font-size: 20px;
    line-height: 30px;
  }
  h5 {
    font-size: 20px;
    line-height: 28px;
  }
  p {
    font-size: 16px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 575px) {
  h4 {
    font-size: 22px;
    line-height: 33px;
  }
}
@media only screen and (max-width: 767px) {
  h1 {
    font-size: 40px;
    line-height: 50px;
    margin: 0 0 10px;
  }
  h2 {
    font-size: 30px;
    line-height: 43px;
  }
  h3 {
    font-size: 25px !important;
    line-height: 35px !important;
  }
  h5 {
    font-size: 23px;
    line-height: 30px;
  }
}
@media (max-width: 600px) {
  .employeeFormHeadingTop {
    display: block;
  }
}
.pageRightSide {
  margin-left: 231px;
  width: calc(100% - 231px);
  padding: 0 20px;
  border-radius: 20px;
}
.pageRightSide.full-width {
  margin-left: 0;
  width: calc(100% - 0px);
  padding: 0 0;
}
.dropdown-menu-user-edit.dropdown-menu.show {
  top: 30px !important;
  left: -150px;
  padding: 0;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: -2px 8px 20px 0px rgba(0, 0, 0, 0.18);
  z-index: 2;
}
.dropdown-menu-user-edit.dropdown-menu .dropdown-item {
  color: var(--text-green);
  border-bottom: 1px solid #3c3c3c;
  padding: 10px;
  text-align: center;
}
.dropdown-menu-user-edit.dropdown-menu .dropdown-item:hover {
  background: var(--primary-bg-color);
  color: #fff;
}
.dropdown-menu-user-edit.dropdown-menu .dropdown-item:last-child {
  border-bottom: transparent;
}
.dashboard-active-link {
  display: none;
}

.side-bar-edit .active {
  background-color: #fafff6;
  padding: 10px 20px;
  color: var(--text-green) !important;
  border-radius: 30px 0px 0px 30px;
  position: relative;
  display: flex;
  align-items: center;
}
.side-bar-edit .active .dashboard-nonactive-link {
  display: none;
}
.side-bar-edit .active .dashboard-active-link {
  display: block;
}

.side-bar-edit .active:before {
  content: "";
  position: absolute;
  content: "";
  position: absolute;
  right: 0px;
  top: -20px;
  width: 20px;
  height: 20px;
  background: radial-gradient(
    ellipse at 0% -25%,
    transparent 0,
    transparent 70%,
    white 70%,
    white 100%
  );
}
.side-bar-edit .active:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -20px;
  width: 20px;
  height: 20px;
  background: radial-gradient(
    ellipse at -25% 100%,
    transparent 0,
    transparent 70%,
    white 70%,
    white 100%
  );
}
.input-wrapper .input-group {
  border: 2px solid rgba(29, 30, 44, 0.3);
  border-radius: 18px;
  height: 48px;
}
.input-wrapper .input-group:hover,
.input-wrapper .input-group:active,
.input-wrapper .input-group:focus {
  border-color: var(--border-green);
}
.input-wrapper img {
  width: 24px;
}
.input-wrapper .input-group-text {
  background: transparent;
  border: 0;
}
.input-wrapper > label {
  color: #1d1e2c;
  font-size: 30.33px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
}
.input-wrapper .form-control {
  color: #1d1e2c;
  font-size: 16px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 0;
  border-radius: 0 18px 18px 0 !important;
  background-color: transparent;
}

.input-wrapper .form-control:hover,
.input-wrapper .form-control:active,
.input-wrapper .form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: rgba(29, 30, 44, 0.3);
}
.input-wrapper .form-control::placeholder {
  font-size: 16px;
  color: #1d1e2c4d;
}
.input-wrapper .form-check-input:checked {
  background-color: var(--primary-bg-color);
  border-color: var(--border-green);
}
.input-wrapper .form-check-input:focus {
  border-color: var(--border-green);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(22, 244, 118, 0.25);
}

.user-modal-edit .modal-content {
  border-radius: 12px;
  box-shadow: -2px 8px 25px 0px #00000040;
}
.modal-user .btn-close {
  background-color: var(--primary-bg-color);
  border-radius: 50%;
  opacity: 1;
}
.modal-user.modal-close svg {
  fill: white;
}
.modal-user.modal-header {
  position: absolute;
  right: 0;
  border: none;
  padding: 0;
  top: -6px;
  right: -5px;
  z-index: 1;
}
.rdt_TableRow {
  position: relative;
  z-index: auto;
}
.rdt_TableRow:not(:last-of-type),
.ddXXyW:not(:last-of-type) {
  border-bottom-style: none !important;
  border-bottom-width: 0 !important;
  border-bottom-color: initial;
}
/* .hYkFWB div:first-child {
  overflow: auto !important;
  white-space: initial !important;
} */
/* .lalHag,
.kdYplh,
.XqRbW,
.fjmwMg,
.EJhlb,
.daanwb,
.ddXXyW,
.cAIFcw  */
.rdt_Table,
.rdt_TableHeadRow,
.rdt_TableRow,
.rdt_Pagination {
  background-color: transparent !important;
}
#userDataTable div:first-child {
  overflow-y: unset !important;
  overflow-x: unset !important;
}
.rdt_TableRow:hover,
.ddXXyW:hover {
  /* color: rgba(0, 0, 0, 0.87); */
  background-color: transparent !important;
  transition-duration: 0.15s;
  transition-property: background-color;
  border-bottom-color: transparent !important;
  outline-style: none;
  outline-width: 1px;
  outline-color: transparent !important;
}
.kbunWL,
.haMfPc {
  overflow-y: unset !important;
  overflow-x: unset !important;
}

/* .kdYplh {
  border-bottom: 0 !important;
} */
.kSXXZB {
  position: relative;
  z-index: auto;
}
/* .eBJjZC, .gGZTER,.kSXXZB{
  padding-right: 2px !important;
  padding-left: 2px !important;
} */

.activatedIconWrapper,
.deactivatedIconWrapper {
  color: rgb(100, 180, 49);
  background-color: rgba(144, 238, 144, 0.14);
  border-color: rgb(100, 180, 49);
  width: 100px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  display: inline-block;
  /* padding: 2px 5px; */
}
.deactivatedIconWrapper {
  border-color: rgba(240, 128, 128, 0.13);
  color: rgb(255, 61, 0);
  background: rgba(240, 128, 128, 0.13);
}
div:where(.swal2-container) h2:where(.swal2-title) {
  font-size: 22px;
}
.signInBtn:disabled {
  opacity: 0.5;
}
.font-xs {
  font-size: 10px;
}
body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow: initial !important;
  padding-right: 0 !important;
}
/* date $ phone  */
.datee-picker-custom-input .react-datepicker__calendar-icon {
  right: 0 !important;
}
.datee-picker-custom-input .react-datepicker__view-calendar-icon input {
  padding: 6px !important;
}
.phone-input-custom .PhoneInputInput {
  max-width: 200px;
}
.client-dtaa-wrapper .Evant-heading {
  background: linear-gradient(180deg, #1d1e2c 0%, #64b431 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}
.client-dtaa-wrapper .profile-head .img-boxx img {
  width: 150px;
  height: 150px;
  border-radius: 15px;
  border: 1px solid #8d818c;
}

.client-dtaa-wrapper .profile-head {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
}
.client-dtaa-wrapper .profile-head .left-content-box h6 {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  margin: 0 0 0;
  color: #1d1e2c;
}
.client-dtaa-wrapper .profile-head .left-content-box p {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  margin-bottom: 0;
  color: #8d818c;
}
.client-dtaa-wrapper .employee-events-details p {
  margin-bottom: 0;
  line-height: 27px;
}
.client-dtaa-wrapper .employee-events-details .right-side-status .valid-acces {
  font-size: 13px;
  line-height: 15px;
  padding: 3px 7px;
  width: auto;
}
.client-dtaa-wrapper .employee-events-details {
  padding: 15px;
  box-shadow: -2px 8px 15px 0px rgba(0, 0, 0, 0.08);
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.client-dtaa-wrapper .profile-head .left-content-box .valid-acces-evetns {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color: #64b431;
}
.client-dtaa-wrapper .employee-events-details .user-left-heading {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  margin-bottom: 0;
  color: #1d1e2c;
  padding-right: 10px;
}
.empolyee-history-wrapper {
  /* max-height: 400px; */
  overflow-y: auto;
}
.user-name {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: #8d818c;
}
.date-employee {
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  margin-top: 2px;
  color: #8d818c;
}
.spinnerLoaderWrapper {
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-event-history {
  background-color: transparent;
  border: 1px solid var(--border-green);
  color: var(--text-green);
}

.btn-event-history:is(:hover, :active, :focus) {
  color: #fff;
  background: var(--primary-btn-bg);
  outline: none;
  box-shadow: none;
  border: 1px solid var(--border-green);
}
.btn-event-history-filled {
  background: var(--primary-btn-bg);
  border: 1px solid var(--border-green);
  color: #fff;
}
.btn-event-history-danger {
  background-color: transparent;
  border: 1px solid red;
  color: red;
}

.btn-event-history-danger:is(:hover, :active, :focus) {
  color: #fff;
  background: red;
  outline: none;
  box-shadow: none;
  border: 1px solid #fff;
}
.pointer-events-none {
  pointer-events: none;
}

@media only screen and (min-width: 1440px) {
  /* .eBJjZC, .gGZTER,.kSXXZB{
    padding-right: 10px !important;
    padding-left: 10px !important;
  } */
}

@media only screen and (max-width: 1340px) {
  .data-table-wrapper .kbunWL {
    overflow-x: scroll !important;
  }
}

/* media Querry  */
@media only screen and (max-width: 991px) {
  .pageRightSide {
    margin-left: 0px;
    width: 100%;
  }
  .side-bar-edit .active {
    border-radius: 30px;
  }
  .side-bar-edit .active::before,
  .side-bar-edit .active::after {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .input-wrapper > label {
    font-size: 19px;
  }
}
@media only screen and (max-width: 576px) {
  .modal-user.modal-header {
    top: 6px;
    right: 6px;
  }
}

.table-responsive ::-webkit-scrollbar {
  width: 8px;
}

.table-responsive ::-webkit-scrollbar-track {
  background-color: transparent;
}

.table-responsive ::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #1d1e2c 0%, #64b431 100%);
  border-radius: 4px;
}

.table-responsive ::-webkit-scrollbar-thumb:hover {
  background-color: #9999;
}
