.fromMainwrapper {
  min-height: 100vh;
  font-size: 16px;
  overflow-y: auto;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  background: rgb(13, 15, 52);
  width: 100%;
}

.fromInnerwrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: Roboto, sans-serif;
  background: transparent;
  -webkit-box-align: center;
  align-items: center;
  width: 610px;
  overflow-wrap: break-word;
}
.topSection {
  padding-bottom: 16px;
  text-align: center;
}
.fontTitle {
  font-size: 32px;
  color: #fff;
  line-height: 38px;
  letter-spacing: 0.5px;
}

.formWrapper {
  display: block;
  background-color: rgb(255, 255, 255);
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 5px 0px,
    rgba(0, 0, 0, 0.2) 0px 1px 2px 0px;
  padding: 12px 30px 30px;
  align-self: stretch;
  margin-bottom: 1.5rem;
  border-radius: 2px;
  word-break: break-word;
}
.topDis {
  margin-top: 18px;
  margin-bottom: 6px;
}
.AnsBlow {
  color: rgb(22, 22, 22);
  font-size: 18px;
  font-weight: 300;
  margin: 3px 0px 0px;
  white-space: pre-wrap;
  line-height: 35px;
}
.textBox .subText {
  font-size: 14px;
  white-space: pre-wrap;
  line-height: 22px;
  margin: 0px;
  color: #000;
  text-align: center;
}

.modalInput {
  display: block;
  width: 100%;
  font-size: 0.88em;
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
  border-radius: 2px;
  font-family: Roboto, sans-serif;
  color: rgb(22, 22, 22);
  border: 1px solid rgb(204, 204, 204);
  min-height: 30px;
  height: 30px;
  padding: 0px 7px;
}
.modalInput:focus {
  box-shadow: none;
}
.labelStyle {
  width: 100%;
  display: block;
  font-weight: bold;
  font-size: 0.88em;
  color: rgb(22, 22, 22);
  white-space: pre-wrap;
  margin-bottom: 6px;
  line-height: 20px;
  padding-left: 0;
}
.TopBorderWrapper {
  border-top: 2px solid rgb(228, 228, 228);
  margin-top: 18px;
  margin-bottom: 6px;
}
.listUpload {
  list-style-type: decimal;
}
.listUpload .listItems {
  font-size: 14px;
  white-space: pre-wrap;
  line-height: 16px;
}
.FileText {
  font-size: 14px;
  white-space: pre-wrap;
  line-height: 16px;
  color: #000;
  margin-bottom: 4px;
}
.linkItem {
  color: rgb(0, 115, 236);
  text-decoration: underline;
  font-size: 14px;
}
.fileUploadbox {
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-radius: 2px;
  position: relative;
  border: 1px dashed rgb(204, 204, 204);
  display: flex;
  flex-direction: column;
  line-height: 15px;
  margin-top: 0px;
  padding: 25px 0px;
  width: 100%;
}
.fileUploadText {
  align-items: center;
  justify-content: center;
  display: block;
  max-width: 100%;
  font-size: large;
  padding: 0px;
}
.fileUploadText .fileText {
  display: inline;
  text-align: center;
  font-size: 14px;
  color: rgb(118, 118, 118);
}
.fileUploadText .fileText button {
  border-color: transparent;
  border-style: none;
  background-color: rgb(255, 255, 255);
  border-width: 1px;
  color: rgb(0, 115, 236);
  font-size: 16px;
  padding-left: 5px;
  padding-right: 0px;
}
.fileInput {
  position: absolute;
  right: -78px;
  opacity: 0;
  cursor: pointer;
}

.checkBoxCus {
  margin: 0px;
  height: 16px;
  width: 16px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 2px;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
}

.submitCustombtn {
  border-radius: 2px;
  font-size: 0.88em;
  background-color: rgb(0, 115, 236);
  align-self: flex-end;
  border-style: none;
  color: rgb(255, 255, 255);
  margin-top: 20px;
  margin-bottom: 0px;
  min-width: 70px;
  height: 30px;
}

.datePickInput {
  background: transparent;
  position: relative;
  z-index: 1;
}
.datePickInput .react-datepicker__calendar-icon {
  position: absolute;
  right: 0;
}

.validationmessage {
  color: #ff5050; /* Red color for validation messages */
  font-size: 14px; /* Adjust font size as needed */
  margin-top: 4px; /* Add some top margin for separation */
}
