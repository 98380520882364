.userDashboardWrapper {
  background-color: #eee;
  padding-block: 20px;
}
.userDashboard {
  background-color: #fafff6;
  padding: 65px 30px 30px 30px;
  border-radius: 12px;
  min-height: calc(100vh - 40px);
  background: url(../../assets/images//BG3.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.leftSide h1 {
  background: linear-gradient(180deg, #1d1e2c 0%, #64b431 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 42.33px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media only screen and (max-width: 576px) {
  .leftSide h1 {
    font-size: 30.33px;
  }
}
