.UserBtn {
  font-weight: 500;
  font-size: 16px;
  line-height: 33px;
  border-radius: 12px;
  color: #fff !important;
  padding-right: 19px;
  /* width: ; */
  background: #64b431;
  border: none;
  min-height: 48px;
  min-width: 230px;
}
.UserBtn span {
  color: #fff;
  margin-left: 6px;
}

@media only screen and (max-width: 767px){
  .UserBtn{
    margin-top: 8px;
  }
}
@media only screen and (max-width: 576px){
  .UserBtn{
   min-width: 100%;
  }
}
