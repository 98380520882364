@import "../../assets/css/utilities/variables";

.loginPageWrapper {
  display: flex;
}
.leftSide {
  max-width: 30%;
  flex: 0 0 30%;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  position: relative;
  background-color: var(--sidebar-bg-color);
}
.rightSide {
  max-width: calc(70% - 60px);
  flex: 0 0 calc(70% - 60px);
  width: 100%;
  background-color: #fafff6;
  margin: 30px;
  border-radius: 20px;
  padding-bottom: 15px;
  background: url(../../assets/images//BG3.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.titleHeading {
  font-size: 48px;
  background: var(--text-title-green);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  padding-top: 100px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.formWrapper {
  max-width: 650px;
  margin-inline: auto;
}

.btnSignin {
  width: 100%;
  border-radius: 16px;
  background: var(--primary-btn-bg);
  color: #fff;
  font-size: 30.33px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 10px 1rem;
  border: 1px solid var(--border-green);
}
.btnSignin:hover,
.btnSignin:active,
.btnSignin:focus {
  box-shadow: none;
  outline: none;
  border: 1px solid var(--border-green);
}
.passIconWrapper {
  position: absolute;
  top: 67px;
  right: 10px;
  z-index: 10;
  cursor: pointer;
}
.presentedByText {
  color: #1d1e2c;
  font-size: 18.33px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
}

@media only screen and (max-width: 1070px) {
  .rightSide {
    padding: 0 20px 20px 20px;
  }
}
@media only screen and (max-width: 767px) {
  .loginPageWrapper {
    flex-direction: column;
  }
  .leftSide {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .rightSide {
    max-width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    margin: 20px 0px;
  }
  .presentedByText {
    bottom: -15px;
  }
  .titleHeading {
    padding-top: 20px;
  }
  .btnSignin {
    font-size: 20px;
  }
  .passIconWrapper {
    top: 51px;
  }
}
